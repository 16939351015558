import { createAction } from "@reduxjs/toolkit";

export const getCustomerDataConfigName = "customer/data";

export const getCustomerDataConfigAction = createAction<any, string>(
  "customer/data"
);

export const setCustomerIdConfigAction = createAction<any, string>(
  "customer/setIdConfig"
);

export const setCustomerNameConfigAction = createAction<any, string>(
  "customer/setNameConfig"
);

export const setChannelIdConfigAction = createAction<any, string>(
  "customer/setChannelIdConfig"
);
export const setEncrCustomerIdConfigAction = createAction<any, string>(
  "customer/setEncrCustomerIdConfig"
);
export const setSchemeCodesConfigAction = createAction<any, string>(
  "customer/setSchemeCodesConfig"
);

export const setCustomerConfigAction = createAction<any, string>(
  "customer/setNameConfig"
);
export const setNtbUserIdConfigAction = createAction<any, string>(
  "customer/setNtbUserIdConfig"
);

export type CustomerActions = {
  setCustomerIdConfigAction(params: any): void;
  getAuthActions: (params: string) => void;
  setCustomerNameConfigAction(params: any): void
  setEncrCustomerIdConfigAction: (encrCustomerId: string) => void;
  setNtbUserIdConfigAction(params: string): void;
  setChannelIdConfigAction(params: any): void;
  setSchemeCodesConfigAction: (schemeCodes: Array<string>) => void;

}
import { combineReducers, ReducersMapObject } from "redux";

import AuthReducer from "./Auth/Reducer";
import { SLICE_NAME as AuthSliceName } from "./Auth/Selectors";

import ServiceTrackerReducer from "./ServiceTracker/Reducer";
import { SLICE_NAME as ServiceTrackerSliceName } from "./ServiceTracker/Selectors";

import InvestmentReducer from "./Investment/Reducer";
import { SLICE_NAME as InvestmentSliceName } from "./Investment/Selectors";

import { SsoReducer } from "./SSO/Reducer";
import { SLICE_NAME as SSOSliceName } from "./SSO/Selectors";

import SpendsReducer from "./SpendsAnalyzer/Reducer";
import { SLICE_NAME as SpendsSliceName } from "./SpendsAnalyzer/Selectors";

import { ActivityReducer } from "./Activity/Reducer";
import { SLICE_NAME as ActivitySliceName } from "./Activity/Selectors";

import { protectionReducer } from "./Protection/Reducer";
import { SLICE_NAME as ProtectionSliceName } from "./Protection/Selectors";

import CustomerDataReducer from "./Customer/Customer.reducer";
import { SLICE_NAME as CustomerSliceName } from "./Customer/Customer.selector";

import { notificationReducer } from "./Notifications/Reducer";
import { SLICE_NAME as NotificationSliceName } from "./Notifications/Selectors";

import { OnboardingReducer } from "./Onboarding/Reducer";
import { SLICE_NAME as OnboardingSliceName } from "./Onboarding/Selectors";

import { CreditReducer } from "./Credit/Credit.reducer";
import { SLICE_NAME as CreditSlicename } from "./Credit/Credit.selector";

import { IncomeReducer } from "./Income/Reducer";
import { SLICE_NAME as IncomeSliceName } from "./Income/Selectors";

import { NetworthReducer } from "./Networth/Reducer";
import { SLICE_NAME as NetworthSlicename } from "./Networth/Selectors";

import { EquityReducer } from "./Equity/Reducer";
import { SLICE_NAME as EquitySlicename } from "./Equity/Selectors";

const reducers: ReducersMapObject = {
  [AuthSliceName]: AuthReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer,
  [InvestmentSliceName]: InvestmentReducer,
  [SSOSliceName]: SsoReducer,
  [SpendsSliceName]: SpendsReducer,
  [ActivitySliceName]: ActivityReducer,
  [ProtectionSliceName]: protectionReducer,
  [CustomerSliceName]: CustomerDataReducer,
  [NotificationSliceName]: notificationReducer,
  [OnboardingSliceName]: OnboardingReducer,
  [CreditSlicename]: CreditReducer,
  [NetworthSlicename]: NetworthReducer,
  [EquitySlicename]: EquityReducer,
  [IncomeSliceName]: IncomeReducer,
};

const persistedReducers: any[] = [];

export default combineReducers(reducers);
export { persistedReducers };

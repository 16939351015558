import { createSelector } from "@reduxjs/toolkit";

export const SLICE_NAME = "equity";

export const getEquitySelector = createSelector(
  (state: any) => state[SLICE_NAME].equity,
  (equity) => equity
);

export const getEquitySsoSelector = createSelector(
  (state: any) => state[SLICE_NAME].equitySso,
  (equitySso) => equitySso
);
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { DataStatus } from "~/src/Lib/types/dataTransfer";
import { ProtectionModel } from "./Model";
import { getProtectionAction } from "./Actions";

export type ProtectionState = {
  protections: ProtectionModel;
};
const initialState = {
  protections: {
    status: DataStatus.NOT_LOADED,
  },
};

const ProtectionSlice = createSlice({
  name: "protections",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getProtectionAction.loading, (state) => {
      state.protections.status = DataStatus.LOADING;
    });
    builder.addCase(getProtectionAction.success, (state, { payload }: any) => {
      state.protections = payload;
    });
    builder.addCase(getProtectionAction.error, (state) => {
      state.protections.status = DataStatus.ERRORED;
    });
  },
});

export const protectionReducer = ProtectionSlice.reducer;

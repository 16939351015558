import { asHttp } from "~/src/Configurations/WebHttp";
import serviceActionCreator from "~/src/Redux/serviceActionCreatorWithTokenRotation";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { OnboardingMapper, onboardingPayload } from "../Model";
import {
  updateOnboardingActions,
  updateOnboardingServiceName,
} from "../Actions";

export type ActivityData = {
  page: string;
  action: string;
};

async function updateOnboardingData({
  customerId,
  watched,
}: onboardingPayload) {
  const channelId = sessionStorage.getItem("channelId") || "";

  const options = {
    url: `${AS_API_DOMAIN}/${updateOnboardingServiceName}`,
    method: "POST",
    data: {
      customerId,
      watched,
      channelId,
    },
  };

  const response = await asHttp.request(options);
  const { data: body } = response;
  const { data } = body;
  return new OnboardingMapper().toModel(data);
}

const updateOnboardingDataServiceAction = serviceActionCreator(
  updateOnboardingActions,
  updateOnboardingData
);

export default updateOnboardingDataServiceAction;

import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { DataStatus } from "~/src/Lib/types/dataTransfer";
import { OnboardingModel } from "./Model";
import { SLICE_NAME } from "./Selectors";
import { getOnboardingActions, updateOnboardingActions } from "./Actions";

export type OnBoardingState = {
  onboarding: OnboardingModel;
};
const initialState = {
  onboarding: {
    status: DataStatus.NOT_LOADED,
  },
};

const OnboardingSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(
      getOnboardingActions.loading || updateOnboardingActions.loading,
      (state) => {
        state.onboarding.status = DataStatus.LOADING;
      }
    );
    builder.addCase(
      getOnboardingActions.success || updateOnboardingActions.success,
      (state, { payload }: any) => {
        state.onboarding = payload;
      }
    );
    builder.addCase(
      getOnboardingActions.error || updateOnboardingActions.error,
      (state) => {
        state.onboarding.status = DataStatus.ERRORED;
      }
    );
  },
});

export const OnboardingReducer = OnboardingSlice.reducer;

import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { DataStatus } from "~/src/Lib/types/dataTransfer";
import { SLICE_NAME } from "./Selectors";
import { getAplyNowCcSsoSSOAction, getSSOAction } from "./Actions";
import { SsoModel } from "./Model";

export type SsoState = {
  sso: SsoModel;
};

const initialState = {
  sso: {
    status: DataStatus.NOT_LOADED,
  },
};

const SsoSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getSSOAction.loading || getAplyNowCcSsoSSOAction.loading, (state) => {
      state.sso.status = DataStatus.LOADING;
    });
    builder.addCase(getSSOAction.success || getAplyNowCcSsoSSOAction.success, (state, { payload }: any) => {
      state.sso = payload;
    });
    builder.addCase(getSSOAction.error || getAplyNowCcSsoSSOAction.error, (state) => {
      state.sso.status = DataStatus.ERRORED;
    });
  },
});

export const SsoReducer = SsoSlice.reducer;

import { DataStatus } from "~/src/Lib/types/dataTransfer";
import { ModelMapper } from "~/src/Lib/types/modelmapper";
export interface OnboardingModel {
  customerId: string;
  created_at: string;
  updated_at: string;
  watchedVideos: boolean;
  status: string;
  statusCode:number;
}
export interface Onboardingdto {
  customerId: string;
  createdAt: string;
  updatedAt: string;
  watchedVideos: {
    home_splash_21: boolean;
  };
  status: string;
  statusCode:number;
}
export type onboardingPayload = {
  customerId: string;
  watched: string;
};

export class OnboardingMapper
  implements ModelMapper<Onboardingdto, OnboardingModel>
{
  toModel(dto: Onboardingdto): OnboardingModel {
    return {
      customerId: dto.customerId,
      created_at: dto.createdAt,
      updated_at: dto.updatedAt,
      watchedVideos: dto?.watchedVideos?.home_splash_21,
      statusCode: dto?.statusCode,
      status: dto?.statusCode === 200 ? DataStatus.LOADED : DataStatus.ERRORED,
    };
  }
  toDto(model: OnboardingModel): Onboardingdto {
    throw new Error("Method not implemented. for " + typeof model);
  }
}

import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { DataStatus } from "~/src/Lib/types/dataTransfer";
import {
  getEquityServiceTraceActions,
  getEquitySsoServiceTraceActions,
} from "./Actions";

export const EQUITY_SSO = {
  ONBOARDING: "EQONBONE",
  TRANSACTION: "EQDASONE",
};

export type TEquitySso = {
  equity: { status: string };
  status: string;
  equitySso: {
    onboardingUrl: string;
    transactionUrl: string;
  };
};

const initialState = {
  equity: {
    status: DataStatus.NOT_LOADED,
  },
  status: DataStatus.NOT_LOADED,
  equitySso: {
    transactionUrl: "",
    onboardingUrl: "",
  },
};

const EquitySlice = createSlice({
  name: "equity",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getEquityServiceTraceActions.loading, (state) => {
      state.equity.status = DataStatus.LOADING;
    });
    builder.addCase(
      getEquityServiceTraceActions.success,
      (state, { payload }: any) => {
        state.equity = payload;
      }
    );
    builder.addCase(getEquityServiceTraceActions.error, (state) => {
      state.equity.status = DataStatus.ERRORED;
    });
    builder.addCase(
      getEquitySsoServiceTraceActions.loading,
      (state, { payload }) => {
        state.equitySso.status = DataStatus.LOADING;
      }
    );
    builder.addCase(
      getEquitySsoServiceTraceActions.success,
      (state, { payload }: any) => {
        state.equitySso = payload;
      }
    );
    builder.addCase(getEquitySsoServiceTraceActions.error, (state) => {
      state.equitySso.status = DataStatus.ERRORED;
    });
  },
});

export const EquityReducer = EquitySlice.reducer;

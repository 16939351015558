import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { DataStatus } from "~/src/Lib/types/dataTransfer";
import { NotificationData, NotificationType, NotificationModel } from "./Model";
import groupBy from "lodash/groupBy";
import { getNotificationsAction } from "./Actions";

export type NotificationState = {
  notifications: NotificationModel;
  notificationsSet: {
    [key in NotificationType]: NotificationData[];
  };
};
const initialState = {
  notifications: { status: DataStatus.NOT_LOADED },
  notificationsSet: {},
};

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getNotificationsAction.loading, (state) => {
      state.notifications.status = DataStatus.LOADING;
    });
    builder.addCase(
      getNotificationsAction.success,
      (state, { payload }: any) => {
        const allNotifications = payload.notifications.filter(
          (element: NotificationData) =>
            element.headerKey && element.headerKey !== ""
        );
        const keyWisenotifications = groupBy(allNotifications, (data) =>
          data?.headerKey?.toLowerCase()
        );
        state.notificationsSet = {
          all: allNotifications || {},
          credit: keyWisenotifications?.credit || {},
          spends: keyWisenotifications?.spends || {},
          investment: keyWisenotifications?.investment || {},
        };
        state.notifications = payload;
      }
    );
    builder.addCase(getNotificationsAction.error, (state) => {
      state.notifications.status = DataStatus.ERRORED;
    });
  },
});

export const notificationReducer = NotificationSlice.reducer;

import traceActionsCreator from "../traceActionsCreator";


export const GET_EQUITY_URL = "/investment/getEquity";
export const GET_EQUITY_SSO_URL = "/sso/equity";

export const getEquityServiceTraceActions = traceActionsCreator(
    GET_EQUITY_URL
);

export const getEquitySsoServiceTraceActions = traceActionsCreator(
    GET_EQUITY_SSO_URL
);
import { asHttp } from "~/src/Configurations/WebHttp";
import serviceActionCreator from "~/src/Redux/serviceActionCreatorWithTokenRotation";
import { AS_API_DOMAIN } from "~/src/Configurations/env";
import { OnboardingMapper } from "../Model";
import { getOnboardingActions, getOnboardingServiceName } from "../Actions";

export type ActivityData = {
  page: string;
  action: string;
};

async function getOnboardingData(customerId: string) {
  const channelId = sessionStorage.getItem("channelId") || "";

  const options = {
    url: `${AS_API_DOMAIN}/${getOnboardingServiceName}`,
    method: "POST",
    data: {
      customerId,
      channelId,
    },
  };

  const response = await asHttp.request(options);
  const { data: body } = response;
  const { data } = body;
  return new OnboardingMapper().toModel(data);
}

const getOnboardingDataServiceAction = serviceActionCreator(
  getOnboardingActions,
  getOnboardingData
);

export default getOnboardingDataServiceAction;

import React from "react";
import { RouteObject, createBrowserRouter } from "react-router-dom";
import {
  FDRDErrorComponent,
  SessionTimeoutComponent,
} from "~/src/Pages/CommonError.page";

import APP_ROUTES from "~/src/Constants/APP_ROUTES";
import AppStore from "./AppStore";

import MainLayout from "~/src/Layouts/Main.Layout";
const HomePage = React.lazy(() => import("~/src/Pages/Home/Home.Page"));

const ProtectionPage = React.lazy(
  () => import("~/src/Pages/Protection/Protection.Page")
);

const InvestmentMainPage = React.lazy(
  () => import("~/src/Pages/Investment/Investment.Page")
);

const NotificationPage = React.lazy(
  () => import("~/src/Pages/Notifications/Notification.Page")
);

const SpendsHomePage = React.lazy(
  () => import("~/src/Pages/Spends/Spends.page")
);

const CommonErrorPage = React.lazy(
  () => import("~/src/Pages/CommonError.page")
);

const NoInternetPage = React.lazy(
  () => import("~/src/Pages/NoInternetError.page")
);

const SomethingWentWrongPage = React.lazy(
  () => import("~/src/Pages/SomethingWentWrong.page")
);

const CreditPage = React.lazy(() => import("~/src/Pages/Credit/Credit.page"));

const CreditLinePage = React.lazy(
  () => import("~/src/Pages/Credit/Creditlines.page")
);
const CreditLineDetailPage = React.lazy(
  () => import("~/src/Pages/Credit/CreditDetail.page")
);

const NetworthPage = React.lazy(
  () => import("~/src/Pages/Networth/Networth.Page")
);

const NetworthFormPage = React.lazy(
  () => import("~/src/Pages/Networth/NetworthForm.Page")
);

const EquityPage = React.lazy(
  () => import("~/src/Pages/Equity/Equity.page")
);

const AddMonthlyIncomePage = React.lazy(
  () => import("~/src/Components/Income/AddMonthlyIncomePage")
);

import { getCustomerDataConfigAction } from "../Redux/Customer/Customer.action";
import { ApplicationErrorBoundary } from "../Components/ApplicationErrorBoundary";

export interface ICustomerData {
  param: string;
  customerId: string;
  channelId: string;
  name: string;
  ntbUserId?: string;
  encrCustomerId: string;
  schemeCodes?: Array<string>;
}

function getUrlParams() {
  const param = new URL(document.URL).searchParams.get("param") as string;
  const customerId = new URL(document.URL).searchParams.get("custId") as string;
  const ntbUserId = new URL(document.URL).searchParams.get(
    "ntbuserId"
  ) as string;
  const channelId =
    (new URL(document.URL).searchParams.get("channelId") as string) || "";
  const customerDataObj: ICustomerData = {
    param,
    customerId,
    ntbUserId,
    channelId: channelId,
    name: "User",
    encrCustomerId: "",
    schemeCodes: [],
  };

  AppStore.dispatch(getCustomerDataConfigAction(customerDataObj));
}

const routeObj: RouteObject[] = [
  {
    element: <MainLayout />,
    errorElement: <ApplicationErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.HOME.pathname,
        element: <HomePage />,
        loader: getUrlParams(),
      },
      {
        path: APP_ROUTES.PROTECTION.pathname,
        element: <ProtectionPage />,
        loader: getUrlParams(),
      },
      {
        path: APP_ROUTES.INVESTMENT.pathname,
        element: <InvestmentMainPage />,
        loader: getUrlParams(),
      },
      {
        path: APP_ROUTES.SPEND_ANALYZER.pathname,
        element: <SpendsHomePage />,
        loader: getUrlParams(),
      },
      {
        path: APP_ROUTES.NOTIFICATION.pathname,
        element: <NotificationPage />,
        loader: getUrlParams(),
      },
      {
        path: APP_ROUTES.NETWORTH.pathname,
        element: <NetworthPage />,
      },
      {
        path: APP_ROUTES.NETWORTH_FORM.pathname,
        element: <NetworthFormPage />,
      },
      {
        path: APP_ROUTES.SOMETHING_WENT_WRONG.pathname,
        element: <SomethingWentWrongPage />,
      },
      {
        path: APP_ROUTES.FDRD_ERROR.pathname,
        element: <FDRDErrorComponent />,
      },
      {
        path: APP_ROUTES.NO_INTERNET.pathname,
        element: <NoInternetPage />,
      },
      {
        path: APP_ROUTES.SESSION_TIMEOUT.pathname,
        element: <SessionTimeoutComponent />,
      },
      {
        path: APP_ROUTES.CREDIT_SCORE.pathname,
        element: <CreditPage />,
      },
      {
        path: APP_ROUTES.CREDIT_LINES.pathname,
        element: <CreditLinePage />,
      },
      {
        path: APP_ROUTES.CREDIT_LINES_DETAIL.pathname,
        element: <CreditLineDetailPage />,
      },
      {
        path: APP_ROUTES.EQUITY.pathname,
        element: <EquityPage />,
      },
      {
        path: APP_ROUTES.INCOME.pathname,
        element: <AddMonthlyIncomePage />,
      },
    ],
  } as RouteObject,
  {
    path: APP_ROUTES.ANY.pathname,
    element: <CommonErrorPage />,
  } as RouteObject,
];

const getAppRouter = () => createBrowserRouter(routeObj);

export default getAppRouter;

import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Customer.selector";

interface StoreParams {
  user: object;
}
const INITIAL_STATE: StoreParams = {
  user: {},
};
import {
  getCustomerDataConfigAction,
  setCustomerIdConfigAction,
  setEncrCustomerIdConfigAction,
  setCustomerNameConfigAction,
  setChannelIdConfigAction,
  setNtbUserIdConfigAction,
  setSchemeCodesConfigAction,
} from "./Customer.action";

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {
    builder.addCase(getCustomerDataConfigAction, (state, { payload }: any) => {
      state.user = payload;
    });
    builder.addCase(setChannelIdConfigAction, (state, { payload }: any) => {
      state.user.channelId = payload;
    });
    builder.addCase(setCustomerNameConfigAction, (state, { payload }: any) => {
      state.user.name = payload;
    });
    builder.addCase(
      setEncrCustomerIdConfigAction,
      (state, { payload }: any) => {
        state.user.encrCustomerId = payload;
      }
    );
    builder.addCase(setSchemeCodesConfigAction, (state, { payload }: any) => {
      state.user.schemeCodes = payload;
    });
    builder.addCase(setCustomerIdConfigAction, (state, { payload }: any) => {
      state.user.customerId = payload;
    });
    builder.addCase(setNtbUserIdConfigAction, (state, { payload }: any) => {
      state.user.ntbUserId = payload;
    });
  },
};

const slice = createSlice(sliceOptions);

export default slice.reducer;

import traceActionsCreator from "../traceActionsCreator";

export const getOnboardingServiceName = "/user/get";
export const getOnboardingActions = traceActionsCreator(
  getOnboardingServiceName
);


export const updateOnboardingServiceName = "user/saveVideoStatus";
export const updateOnboardingActions = traceActionsCreator(
  updateOnboardingServiceName
);